:root {
  // SF specific vars

  --sf-white: #fff;
  --sf-black: #000;
  --sf-primary-yellow: #f8ab00;
  --sf-primary-orange: #f7aa00;
  --sf-primary-orange-light: #fff1d4;
  --sf-primary-light: #fef5e2;
  --sf-primary-blue: #13243a;
  --sf-body-copy-navy: var(--sf-primary-blue);

  --sf-body-copy: #454e50;
  --sf-body-bg: #f5f6f6;
  --sf-body-bg-darker: #0c1c30;
  --sf-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  --sf-box-shadow-darker: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  --sf-secondary-light-blue: #87d0f1;
  --sf-secondary-dark-blue: #005d85;
  --sf-tertiary-dark-pink: #89254f;

  --sf-grey-vlight: #f6f6f6;
  --sf-grey-light: #c7c7c7;
  --sf-grey-mid: #aaa;
  --sf-grey-dark: #65707f;
  --sf-grey-vdark: #13243a;
  --sf-pale-green: #118c7f;
  --sf-success: #004e45;
  --sf-green-light: #e8f3ee;
  --sf-bottom-shadow: 0 1px 6px -2px #777777;
  --sf-nav-bg: var(--sf-white);

  --sf-border-radius: 6px;
  --sf-gray-border-color: #e9ecef;
  --sf-gray-light-border-color: #dee2e6;
  --sf-disabled: #eaebeb;
  --sf-card-padding: 1rem;
  --sf-card-header: var(--sf-primary-blue);
  --sf-card-sub-header: var(--sf-grey-dark);
  --sf-card-border: #cbcbcb;
  --sf-warning: var(--sf-tertiary-dark-pink);
  --sf-alert-warning: #842029;
  --sf-alert-warning-bg: #fbe8ea;
  --sf-alert-warning-border: #842029;
  --sf-alert-warning-icon: #842029;
  --sf-alert-info-light-bg: var(--sf-primary-light);
  --sf-alert-info-light-border: var(--sf-primary-yellow);
  --sf-alert-info-light-icon: var(--sf-primary-yellow);
  --sf-alert-success: #0f5132;
  --sf-alert-success-bg: #e8f3ee;
  --sf-alert-success-border: #0f5132;
  --sf-alert-success-icon: #0f5132;
  --sf-red: #dc3545;

  // NEW BORDER COLORS
  --sf-light-grey--tints: #f5f6f6;
  --sf-light-red-tints: #fbe8ea;
  --sf-light-orange-tints: #ffe5d0;
  --sf-light-green-tints: #e8f3ee;
  --sf-light-blue-tints: #e1f3fb;
  --sf-light-yellow-tints: var(--sf-primary-light);
  --sf-white-10-opacity-tints: #ffffff1a;
  --sf-white-20-opacity-tints: #ffffff33;

  --sf-success-tint: #e8f3ee;
  --sf-error-tint: #fbe8ea;
  --sf-warning-tint: #ffe5d0;
  --sf-info-a-tint: var(--sf-primary-light);
  --sf-info-b-tint: #e1f3fb;
  --sf-info-a-border: #f8ab00;
  --sf-info-b-border: var(--sf-secondary-dark-blue);
  --sf-warning-border: #984c0c;
  --sf-success-border: #198754;
  --sf-error-border: #b11021;
  --sf-info-a-tint-dark: #3e3b28;
  --sf-info-b-tint-dark: #2c405b;
  --sf-success-tint-dark: #224642;
  --sf-error-tint-dark: #3c3445;
  --sf-warning-tint-dark: #3e3b28;
  --sf-success-border-dark: #20c997;
  --sf-warning-border-dark: #fd7e14;
  --sf-info-b-border-dark: #87d0f1;
  --sf-error-border-dark: #dc3545;

  // DARK THEME ------------------------//
  --sf-body-bg__dark-theme: var(--sf-body-bg-darker);
  --sf-body-copy__dark-theme: var(--sf-white);
  --sf-nav__dark-theme: var(--sf-primary-blue);
  --sf-bottom-shadow__dark-theme: var(--sf-bottom-shadow-darker);
  --sf-card-background__dark-theme: var(--sf-primary-blue);
  --sf-input-background__dark-theme: var(--sf-body-bg-darker);
  --sf-border-color__dark-theme: var(--sf-white-20-opacity-tints);
  --sf-alert-info-bg__dark-theme: var(--sf-info-a-tint-dark);
  --sf-action-button__dark-theme: var(--sf-white-10-opacity-tints);
  --sf-alert-info-icon__dark-theme: var(--sf-white);
  --sf-gray-border-color__dark-theme: var(--sf-white-10-opacity-tints);
  --sf-alert-warning-bg__dark-theme: var(--sf-error-tint-dark);
  --sf-alert-warning-icon__dark-theme: var(--sf-error-border-dark);
  --sf-alert-success-bg__dark-theme: var(--sf-success-tint-dark);
  --sf-alert-success-icon__dark-theme: var(--sf-success-border-dark);
  --sf-disabled__dark-theme: var(--sf-white-10-opacity-tints);
  --sf-input-focus-color: var(--sf-body-bg-darker);

  // Bootstrap var overrides ------------------------//
  --bs-font-sans-serif: "Brandon Text", sans-serif;
  --bs-body-color: var(--sf-body-copy);
  --bs-danger-rgb: var(--sf-tertiary-dark-pink);
}

$grid-breakpoints: (
  xs: 0,
  ti: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// NOT IN USE
// --sf-secondary-mid-blue: #1199d6;
// --sf-tertiary-light-pink: #c56683;
// --sf-tertiary-dark-pink: #89254f;
// --sf-tertiary-light-green: #18988b;
// --sf-tertiary-dark-green: #25a96b;
//--sf-red-light: #fee2e280;
//--sf-red-dark: #a91e33;
