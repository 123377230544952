@font-face {
  font-family: "Brandon Text";
  src: url('/assets/fonts/BrandonText-Regular.woff2') format("woff2");
}

body {
  font-family: "Brandon Text", Arial !important;
}

h1,h2,h3,h4,h5,h6 {
  color: var(--sf-primary-blue);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}
h1 {
  font-size: 26px;
  color: var(--sf-primary-orange);
  margin-top: 0.5rem !important;
  font-weight: normal;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 16px;
}

