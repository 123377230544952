/* You can add global styles to this file, and also import other style files */
body {
  background-color: #87d0f140;
}
.marginBtm15 {
  margin-bottom: 15px !important;
}
.marginBtm30 {
  margin-bottom: 30px !important;
}

.repeater-container {
  display: inline;
}

.split-width {
  display: inline-table;
  width: 50%;
}

.card {
  margin-bottom: 15px;
}

.button__container {
  padding: 10px;
}

.content {
  display: flex;
}

.documentUploadContainer {
  width: fit-content;
}

.navigation__container {
  justify-content: space-between;
  display: flex;
  margin: 2rem;
}

.new-modal {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  overflow: hidden;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.loading-modal-content {
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-text {
  color: #fff;
  font-family: "Roboto Slab" sans-serif;
  font-size: 37px;
  font-weight: bold;
  padding-top: 20px;
  line-height: 1.1;
  text-align: center;
}

.modal-cog {
  color: #fff;
  margin: auto;
  display: block !important;
  width: 50px;
}

.repeater-container .split-width:first-child {
  padding-right: 5px;
}

.NW20-dbImg {
  width: 30%;
}

#appHeader {
  padding: 40px 0px;
  /* background: #87d0f140; */
}

.application__header {
  display: flex;
  justify-content: center;
  /* background-color: #87d0f140 !important; */
}

.application__content {
  display: flex;
  justify-content: center;
}

.form__container-title {
  max-width: 547px;
  width: 100%;
  padding: 0 40px;
}

.NW20-appContent {
  padding: 0px 15px 50px 15px !important;
  /* background-color: #87d0f140 !important; */
}

.NW20-container {
  max-width: 445px;
}

.form__title {
  font-family: "Brandon Text", Arial !important;
  color: #f8ab00 !important;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  margin: 0px;
}

.additional__text-info {
  margin-top: 0 !important;
}

.form__intro {
  font-family: "Brandon Text", Arial !important;
  color: #454e50 !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  margin: 20px 0px 20px 0px;
}

.NW20-form-group {
  margin: 0 auto !important;
}

label {
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
  color: #454e50;
}

.form__subheading {
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
  color: #454e50;
}

.NW20-input-group {
  border: 1px solid #454e50;
  border-radius: 5px;
}

.NW20-input-group .input-group-addon {
  border: none;
  border-radius: 5px;
  background: #ffffff;
}

.NW20-input-group .appFormCurrencyInput {
  border: none;
  border-radius: 5px;
  font-family: "Brandon Text", Arial;
  color: #454e50;
  font-size: 16px;
}

.NW2-radioBtnsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 2.5rem;
}

.NW20-radioBtn {
  position: relative;
  display: inline-block;
  width: 49%;
}

.NW20-radioBtnsSmall {
  position: relative;
  display: inline-block;
  width: 24%;
}

.NW20-radioBtn input[type="radio"],
.NW20-newLabel input[type="radio"],
.NW20-radioBtnsSmall input[type="radio"] {
  opacity: 0;
  position: absolute;
  height: 100%;
  cursor: pointer;
  width: 100%;
}

.NW20-radioBtnsSmall input[type="radio"] + label {
  color: #454e50;
  border: 1px solid #454e50;
  width: 98%;
  padding: 18px;
  background-color: transparent;
  border-radius: 3px;
  text-align: center !important;
  cursor: pointer;
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.NW20-newLabel {
  width: 49%;
}

.NW20-radioBtn input[type="radio"]:checked + label,
.NW20-radioBtnsSmall input[type="radio"]:checked + label {
  color: #ffffff;
  border: 1px solid #13243a;
  background-color: #13243a;
  font-weight: bold;
}

.appFormCurrencyInput {
  border: 0px;
  border-radius: 0px;
  color: #13487a;
  font-family: "Roboto";
  padding: 6px 10px !important;
  font-size: 16px;
  border-left: 1px solid #ccc;
}

.appFormPostCodeSearch {
  border: 0px;
  border-radius: 0px;
  color: #13487a;
  font-family: "Roboto";
  padding: 6px 10px !important;
  font-size: 16px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 15px;
}

.NW20-addressRow {
  display: inline-block;
  width: 75%;
}

.NW20-addressRow:last-of-type {
  width: 25%;
}

.NW20-appFormPostCodeSearch {
  border: 1px solid #454e50;
  border-radius: 5px;
  color: #454e50;
  font-family: "Brandon Text", Arial;
  padding: 6px 10px !important;
  font-size: 16px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 15px;
  height: 43px;
}

.appFormPostCodeBtn {
  background-color: #98c662;
  padding: 4px 15px;
  font-family: "Roboto";
  color: #fff;
  font-size: 16px;
  border-radius: 0px;
  text-align: center;
  display: block;
  margin: 0px auto;
  width: 100% !important;
}

.appFormPostCodeBtn:hover {
  background-color: #89b258;
  color: #fff;
  text-decoration: none;
}

.infoMessage {
  position: relative;
  padding: 10px;
  border-radius: 3px;
  background: #a55b98;
  border: 2px solid #a55b98;
  text-align: center;
  margin-bottom: 25px;
  border-radius: 0px;
}

.infoMessage:after,
.infoMessage:before {
  top: 100%;
  left: 15%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 2px solid;
}

.infoMessage:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #a55b98;
  border-width: 16px;
  margin-left: -30px;
}

.infoMessage:before {
  border-color: rgba(245, 37, 37, 0);
  border-top-color: #a55b98;
  border-width: 19px;
  margin-left: -33px;
}

.errorMessageTitle {
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 5px;
}

.infoMessageTitle {
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 5px;
}

.infoMessageContent {
  font-family: "Roboto";
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 0px;
}


.appQuoteTitle {
  font-family: "Roboto Slab";
  color: #13487a;
  font-size: 36px;
  font-weight: bold;
  margin: 0px 0px 30px 0px;
}

.NW20-appQuoteTitle {
  font-family: "Brandon Text", Arial;
  color: #13243a;
  font-size: 32px;
  font-weight: bold;
  margin: 0px 0px 30px 0px;
}

.appQuoteIntro {
  font-family: "Roboto Slab";
  color: #13487a;
  font-size: 20px;
  margin: 0px 0px 30px 0px;
  line-height: 28px;
  font-weight: 300;
}

.NW20-appQuoteIntro {
  font-family: "Brandon Text", Arial;
  color: #454e50;
  font-size: 16px;
  margin: 0px 0px 30px 0px;
  line-height: 1.6;
  font-weight: 400;
}

.appQuoteSubtitle {
  font-family: "Roboto Slab";
  color: #13487a;
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0px 30px 0px;
}

.NW20-appQuoteSubtitle {
  font-family: "Brandon Text", Arial !important;
  color: #f7aa00 !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 0px 0px 30px 0px;
}

.appQuoteSidebarBtn {
  width: 100%;
  display: block;
  background-color: #98c662;
  padding: 15px;
  font-family: "Roboto";
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 0px;
}

.NW20-appQuoteSidebarBtn {
  margin-bottom: 0px;
  margin: 41px 0px !important;
}

.NW20-appQuoteSidebarBtnLgMg {
  margin: 52px 0px !important;
}

.appQuoteSidebarBtn:hover {
  background-color: #89b258;
  color: #fff;
  text-decoration: none;
}

.appQuoteSidebarHelp {
  padding: 30px;
  border: 2px solid #a04591;
  background-color: #fff;
}

.NW20-appQuoteSidebarHelp {
  padding: 35px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}

.NW20-arrows-icon {
  width: 50%;
  margin-bottom: 20px;
}

.appQuoteSidebarTitle {
  font-family: "Roboto Slab";
  color: #a04591;
  font-size: 28px;
  font-weight: bold;
  margin: 0px 0px 30px 0px;
}

.NW20-appQuoteSidebarTitle {
  font-family: "Brandon Text", Arial;
  color: #13243a;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin: 0px 0px 30px 0px;
}

.appQuoteSidebarText {
  font-family: "Roboto";
  color: #13487a;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 15px;
}

.appQuoteSidebarUL {
  font-family: "Roboto";
  color: #13487a;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0px;
}

.NW20-appQuoteSidebarText {
  font-family: "Brandon Text", Arial;
  color: #454e50;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 15px;
  text-align: center;
  padding: 0px;
}

.NW20-appQuoteSidebarText li {
  list-style: none;
}

.NW20-appQuoteSidebarText li:before {
  content: "-";
  margin-right: 5px;
}

.appDownloadPanel {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
}

.NW20-appDownloadPanel {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.appDownloadPanelText {
  font-family: "Roboto";
  color: #13487a;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0px;
}

.NW20-appDownloadPanelText {
  font-family: "Brandon Text", Arial !important;
  color: #454e50 !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
  margin-bottom: 0px !important;
  font-weight: 400;
}

.quoteResultAmount {
  font-family: "Roboto Slab";
  color: #13487a;
  font-size: 30px;
  font-weight: bold;
  margin: 30px 0px;
}

.NW20-quoteResultAmount {
  font-family: "Brandon Text", Arial !important;
  color: #f7aa00 !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 30px 0px;
}

.appDownloadPanel table {
  color: #13487a;
  font-family: "Roboto";
  font-size: 16px;
  margin-bottom: 0px;
}

.NW20-appDownloadPanel .NW20-tableQ {
  color: #454e50;
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 400;
}

.NW20-appDownloadPanel .NW20-tableQ td,
.NW20-appDownloadPanel .NW20-tableQ th {
  border-top: none;
}

.appQuoteDownloadLinks {
  font-family: "Roboto";
  font-size: 16px;
  color: #13487a;
  margin-top: 30px;
  margin-bottom: 0px;
}

.appQuoteDownload {
  font-family: Roboto;
  font-size: 16px;
  color: #13487a;
}

.NW20-appQuoteDownload {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 20px 0px 10px;
  font-size: 16px;
  font-family: "Brandon Text", Arial;
  color: #13243a;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1.5;
}

.NW20-pdfIcon {
  width: 4rem;
}

.appQuoteOptionsText {
  font-family: "Roboto";
  color: #13487a;
  font-size: 16px;
  line-height: 1.4;
  min-height: 70px;
  margin-bottom: 15px;
}

.NW20-appQuoteOptionsText {
  font-family: "Brandon Text", Arial;
  color: #454e50;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  min-height: 70px;
  margin-bottom: 15px;
}

.NW20-appQuoteOptionsText {
  font-family: "Brandon Text", Arial;
  color: #454e50;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  min-height: 70px;
  margin-bottom: 15px;
}

.appQuoteApplyBtn {
  width: 100%;
  display: block;
  background-color: #98c662;
  padding: 15px;
  font-family: "Roboto";
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 0px;
}

.appQuoteApplyBtn:hover {
  background-color: #89b258;
  color: #fff;
  text-decoration: none;
}

.appQuoteApplyBtn[disabled] {
  background-color: #b1b1b1;
}

.appQuoteAmendBtn {
  width: 100%;
  display: block;
  background-color: #a04591;
  padding: 15px;
  font-family: "Roboto";
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 0px;
}

.appQuoteAmendBtn:hover {
  background-color: #903e82;
  color: #fff;
  text-decoration: none;
}

.NW20-yellowBtn {
  display: inline-block;
  background-color: #f7aa00 !important;
  padding: 15px 32px !important;
  font-family: "Brandon Text", Arial !important;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  text-align: center !important;
  width: auto !important;
  border: 2px solid #ffffff !important;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 20px;
}

.NW20-yellowBtn:hover {
  background-color: #f7aa00;
  color: #fff;
  text-decoration: none;
}

.btn__confirm {
  background-color: #f7aa00;
  padding: 13px 77px;
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
  text-align: center;
  width: auto;
  border: 2px solid #ffffff;
  font-weight: bold;
  margin-top: 0;
}

.btn__confirm:hover {
  background-color: #f7aa00;
  color: #fff;
  text-decoration: none;
}

.sport__btn {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 13.5rem;
  background-color: transparent;
  border: 0.2rem solid #f7aa00;
}

.sport__btn-remove,
.sport__btn-sign {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.sport__btn-remove::after,
.sport__btn-sign::after,
.sport__btn-sign::before {
  content: "";
  position: absolute;
  height: 0.2rem;
  width: 100%;
  top: 0.8rem;
  left: 0.15rem;
  margin-top: -0.1rem;
  background: #f7aa00;
  transform: rotate(0deg);
}

.sport__btn-sign::after {
  transform: rotate(-90deg);
}

.sport__btn.sport__btn--disabled {
  pointer-events: none;
  border: 0.2rem solid #b9b9b9;
}

.sport__btn--disabled .sport__btn-sign::after,
.sport__btn--disabled .sport__btn-sign::before {
  background: #b9b9b9;
}

.sport__btn-remove::after {
  transform: rotate(0deg);
}

.sport__tooltip {
  position: relative;
  padding: 0.1rem;
}

.sport__tooltip:before {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.sport__tooltip:before {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 0.2rem;
  color: #fff;
  content: attr(data-title);
  font-size: 1.4rem;
  padding: 0.6rem 1rem;
  top: 2.6rem;
  white-space: nowrap;
}

.sport__tooltip.expand:before {
  transform: scale3d(0.2, 0.2, 1);
  transition: all 0.2s ease-in-out;
}

.sport__tooltip.expand:hover:before {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  top: 5rem;
  left: -1.5rem;
  z-index: 999;
}

.NW20-blueBtn {
  display: block;
  background-color: #13243a !important;
  padding: 15px 32px !important;
  font-family: "Brandon Text", Arial !important;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  text-align: center !important;
  width: auto !important;
  border: 2px solid #ffffff !important;
  font-weight: bold;
  margin-bottom: 20px;
}

.NW20-blueBtn:hover {
  background-color: #13243a;
  color: #fff;
  text-decoration: none;
}

.NW20-appFormPostCodeBtn {
  padding: 11px 19px !important;
  margin-bottom: 3px;
  margin-left: 10px;
}

.NW20-yellowBtn:focus,
.NW20-blueBtn:focus {
  outline: none;
}

.NW20-fullWidthBtn {
  width: 100% !important;
}

.NW20-maxDate {
  display: inline-block;
  margin-top: 5px;
  padding: 15px 32px !important;
}

.NW20-planReviewSection {
  padding: 40px 0px;
  /* background-color: #87d0f140 !important; */
}

.NW20-planReviewTitle {
  font-family: "Brandon Text", Arial !important;
  color: #13243a !important;
  font-weight: bold !important;
  font-size: 32px !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  font-weight: bold !important;
}

.NW20-planReviewIntro,
.NW20-planReviewIntro label {
  font-family: "Brandon Text", Arial !important;
  color: #454e50 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-top: 0px !important;
  margin-bottom: 30px !important;
  font-weight: 400 !important;
}

.NW20-planReviewIntro label {
  font-weight: 500 !important;
  margin: 0px 5px 0px 0px !important;
}

.NW20-reviewSubHeading {
  font-family: "Brandon Text", Arial !important;
  color: #13243a !important;
  font-weight: bold !important;
  font-size: 24px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.NW20-table th,
.NW20-table td {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
}

.NW20-table thead,
.NW20-table tbody {
  display: block;
}

.NW20-table tr {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
}

.NW20-table tr:last-of-type {
  border-bottom: none;
}

.NW20-table td,
.NW20-table th {
  width: 50%;
  border: none !important;
}

.NW20-table {
  display: block;
  padding: 30px;
  border-radius: 5px;
}

.NW20-table th,
.NW20-table td {
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  color: #454e50;
  font-weight: 400;
  border-radius: 5px;
}

.NW20-table tr td:first-of-type {
  font-weight: bold;
}

.NW20-table .NW20-planReviewSubtitle {
  font-family: "Brandon Text", Arial !important;
  color: #f7aa00 !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.NW20-tableBlueHeading {
  border: none;
}

.NW20-tableBlueHeading thead th {
  color: #ffffff;
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  font-weight: 400;
  background-color: #13243a;
  border-bottom: none !important;
}

.NW20-tableBlueHeading thead th:first-of-type {
  border-top-left-radius: 10px;
}

.NW20-tableBlueHeading thead th:last-of-type {
  border-top-right-radius: 10px;
}

.NW20-tableBlueHeading td {
  font-family: "Brandon Text", Arial !important;
  color: #454e50 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 8px !important;
  vertical-align: middle !important;
}

.NW20-tableBlueHeading .NW20-yellowBtn {
  margin: 10px 0px;
}

/* Small Devices, Tablets */

@media (min-width: 360px) {
  .errorMessage:after,
  .errorMessage:before,
  .infoMessage:after,
  .infoMessage:before {
    left: 12%;
  }
}

@media (max-width: 768px) {
  .NW20-pdfIcon {
    width: 3.5rem;
  }

  .NW20-appQuoteSidebarBtn {
    margin-top: 0px !important;
  }

  .NW20-appQuoteOptionsText {
    min-height: 0px;
  }

  .NW20-addressRow {
    width: 100%;
  }

  .NW20-appFormPostCodeBtn {
    margin-left: 0;
  }

  .NW20-addressRow:last-of-type {
    width: 100%;
  }
  /*
  .NW20-blueBtn {
    width: 100% !important;
  } */
}

/* Small Devices, Tablets */

@media (min-width: 768px) {
  .form__title {
    font-size: 42px;
  }
  .appFormIntro {
    font-size: 26px;
  }
  .errorMessage:after,
  .errorMessage:before,
  .infoMessage:after,
  .infoMessage:before {
    left: 7%;
    top: 22%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .errorMessage:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 10px;
    margin-top: -11px;
  }
  .errorMessage:before {
    border-color: rgba(245, 37, 37, 0);
    border-width: 11px;
    margin-top: -12px;
    border-right-color: #d24747;
  }
  .infoMessage:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 10px;
    margin-top: -11px;
  }
  .infoMessage:before {
    border-color: rgba(245, 37, 37, 0);
    border-width: 11px;
    margin-top: -12px;
    border-right-color: #a55b98;
  }
}

/* Apple iPad's and BlackBerry PlayBook */

@media only screen and (min-width: 992px) {
  .form__title {
    font-size: 54px;
  }
  .appFormIntro {
    font-size: 30px;
  }
  .errorMessage:after,
  .errorMessage:before,
  .infoMessage:after,
  .infoMessage:before {
    left: 5%;
    top: 28%;
  }

  .NW20-accountBlueBackground {
    padding: 15px 15px 0px 15px !important;
  }

  .NW20-overflowX {
    overflow: initial;
  }
}
/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
  .errorMessage:after,
  .errorMessage:before,
  .infoMessage:after,
  .infoMessage:before {
    left: 4%;
    top: 30%;
  }
}

/* Temp For Dec */
.questions-group {
  max-width: 545px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  margin-bottom: 45px;
  padding: 41px 50px 42px 50px;
}

.s-container {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}
