.wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 564px);
}

.footer {
  background: var(--sf-primary-blue);
  color: var(--sf-white);
  padding: 56px 80px;

  @media (max-width: 725px) {
    padding: 40px 16px;
  }

  &__row {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;

    @media (max-width: 995px) {
      flex-direction: column;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__social {
    display: flex;
    gap: 32px;
    flex-direction: column;

    &-list {
      padding-left: 0;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &-item {
      display: inline-block;
      margin: 0 10px;
      font-size: 1.5rem;

      &:first-child {
        margin-left: 0px;
      }

      a {
        color: var(--sf-white);
      }
    }
  }

  &__tel-number {
    padding-bottom: 10px;
    span {
      font-weight: bold;
      color: var(--sf-primary-yellow);
    }
  }

  &__email {
    span {
      font-weight: bold;
      color: var(--sf-primary-yellow);
    }
  }

  &__company-name {
    font-size: 14px;
    opacity: 0.7;
    padding-bottom: 16px;

    @media (min-width: 995px) {
      display: block;
    }

    @media (max-width: 995px) {
      display: none;
    }

    &-mobile {
      font-size: 14px;
      opacity: 0.7;
      padding-bottom: 16px;

      @media (min-width: 995px) {
        display: none;
      }
    }
  }

  &__help {
    a {
      color: var(--sf-white);

      &::before {
        content: "";
        display: inline-block;
        margin-right: 14px;
        width: 24px;
        height: 24px;
        vertical-align: bottom;
        background: url("https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/04/arrow-hollow.svg")
          50% no-repeat;
        background-size: contain;
      }
    }
  }

  &__nav {
    flex: 1;

    &-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      list-style: none;
      padding: 0;
      gap: 12px;

      @media (max-width: 995px) {
        justify-content: space-between;
      }

      @media (max-width: 725px) {
        flex-direction: column;
        gap: unset;
      }

      &-icon {
        outline: none;
        display: inline-block;
        margin-bottom: 18px;
        font-size: 20px;
        font-weight: 700;
        color: var(--sf-primary-yellow);
        text-decoration: none;

        @media (max-width: 725px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #fff;
          padding: 12px 0;
          margin-bottom: 0;
        }

        &::after {
          @media (max-width: 725px) {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../../icons/arrow-hollow.svg) 50% no-repeat;
            background-size: contain;
            transform: rotate(90deg);
            transition: 0.3s;
          }
        }
        &--active {
          &::after {
            @media (max-width: 725px) {
              background: url(../../icons/close-button.svg) 50% no-repeat;
              background-size: contain;
              transform: rotate(0);
            }
          }
        }
      }
    }
    &-submenu {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin: 0;

      @media (max-width: 725px) {
        display: none;
        padding-top: 16px;

        &--active {
          display: block;
        }
      }

      li {
        align-self: stretch;

        &:not(:last-child) {
          padding-bottom: 12px;
        }

        a {
          color: var(--sf-white);
          text-decoration: none;

          &:hover {
            color: var(--sf-primary-yellow);
          }

          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 12px;
            margin-right: 10px;
            background: url(../../icons/chevron-right-secondary.svg) 50%
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  &__copyright {
    p {
      font-size: 14px;
      opacity: 0.7;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
