//REPEATED
.btn {
  text-align: center;
  border-radius: 50px;
  padding: 12px 26px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--sf-body-copy-navy);
  border: 2px solid var(--sf-white);
  flex-grow: 1;
  font-weight: 600;
  display: flex;
  align-items: center;

  :first-child {
    :active {
      color: unset;
      background-color: unset;
    }
  }

  &.inline {
    flex-grow: 0;
  }

  &.no-border {
    border: none;
  }

  &.btn-small {
    padding: 8px 20px;
    font-size: 14px;
    flex-grow: 0;
  }

  &.full-width {
    flex-grow: 1;
  }

  &.btn-link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  //   &:hover {
  //     filter: brightness(90%);
  //   }

  &.primary {
    background-color: var(--sf-primary-orange) !important;
  }

  &.secondary {
    color: var(--sf-white);
    background-color: var(--sf-primary-blue);
  }

  &.tertiary {
    background-color: var(--sf-grey-light);
  }

  &.success {
    background-color: var(--sf-alert-success);
    color: var(--sf-white);
  }

  &.warning {
    background-color: var(--sf-alert-warning);
    color: var(--sf-white);
  }

  &.white {
    background-color: var(--sf-white);
    box-shadow: var(--sf-box-shadow);
    color: var(--sf-body-copy-navy);
  }

  &__confirm {
    display: block;
    padding: 14px 20px;
    width: 35%;
    flex-grow: unset;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: var(--sf-disabled);
    color: var(--sf-grey-dark);

    &:hover {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:active {
      transform: scale(1);
    }
  }

  &-upload {
    i {
      margin-right: 12px !important;
      margin-left: 0 !important;
      font-size: 20px;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  svg {
    margin: 0 6px;
    path {
      fill: var(--sf-body-copy-navy);
    }
  }
}

.btn i {
  margin: 0 6px;
}
