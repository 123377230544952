.info-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 20px 0;
  margin: 20px auto;

  @media screen and (min-width: 1024px) {
    max-width: 960px;
    min-height: calc(100vh - 564px);
    height: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 20px 0 40px 0;

    @media screen and (min-width: 1024px) {
      margin: 60px 0 80px 0;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__title {
    font-size: 24px;
    color: var(--sf-primary-blue);
    font-weight: 600;
    line-height: 28px;
    margin: 0 !important;
  }

  &__list {
    margin-bottom: 16px;

    li {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;

      &::marker {
        font-size: 8px;
      }
    }
  }

  &__welcome-message {
    max-width: 445px;
    font-size: 16px;
    padding: 16px;
    margin-bottom: 0;

    @media screen and (min-width: 1024px) {
      max-width: unset;
    }

    a {
      color: var(--sf-black);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    max-width: 445px;
    flex: 1;
    padding: 16px;
    margin-bottom: 0;

    @media screen and (min-width: 1024px) {
      max-width: unset;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      line-height: 24px;

      &:first-child {
        padding-bottom: 12px;
      }
    }
  }

  &__button {
    padding: 16px 32px;
    justify-content: center;
    flex-grow: unset;

    &--login {
      margin-top: auto;
    }
  }
}
