.pagination-c {
    border-radius: var(--sf-border-radius);
    box-shadow: var(--sf-box-shadow);

    .page-item {
        width: 100%;
        text-align: center;
    }

    .page-link {
        border: 1px solid var(--sf-gray-light-border-color);
        color: var(--sf-body-copy-navy);
        font-weight: bold;
        padding: 12px 0;
        font-size: 16px;
    }

    .pagination-prev > .page-link,
    .pagination-next > .page-link {
        text-decoration: none;
        font-weight: 400;
    }
    .active > .page-link {
        background: var(--sf-primary-orange);
        color: var(--sf-body-copy-navy);
    }
}
