.your-documents {
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    @media (min-width: 650px) {
      margin-bottom: 16px;
    }
  }

  &:last-of-type {
    margin-bottom: 100px;
  }

  &__wrapper {
    margin-top: 60px;
    width: 100%;
  }

  &__info {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    font-weight: bold;

    @media (max-width: 650px) {
      grid-template-columns: 1fr 1fr;
      display: none;
    }
  }

  &__upload {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    border-bottom: 1px solid #f6f6f6;
    padding: 12px 0;

    &:last-of-type {
      border-bottom: none;
    }

    p {
      @media (min-width: 650px) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 650px) {
      grid-template-columns: 1fr;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--sf-grey-vlight);
      }

      &:not(:first-of-type) {
        padding: 16px 0;
      }
    }

    &--info {
      display: none;
      @media (max-width: 650px) {

        display: block;
        margin-bottom: 0;
      }
    }
  }

  &__uploaded {
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: 650px) {
      justify-content: start;
    }

    &--btn {
      display: flex;
      align-items: center;
      justify-content: end;
      background-color: var(--sf-green-light);
      color: var(--sf-success);
      text-align: center;
      border-radius: 50px;
      padding: 13px 24px;
      font-size: 16px;
      font-weight: 600;
    }

    i {
      margin-right: 12px;
      font-size: 21px;
    }
  }

  .ngx-file-drop__content {
    height: unset !important;
  }
}
