.steps {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  padding: 1rem 0.4rem;

  @media screen and (min-width: 420px) {
    padding: 1rem;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    @media screen and (min-width: 420px) {
      gap: 1px;
    }
  }

  &__list-item {
    list-style-type: none;
    width: 100%;
  }

  &__list-item-content {
    font-size: 10px;

    margin-bottom: 0;

    @media screen and (min-width: 420px) {
      font-size: 14px;
    }

    &--selected,
    &--completed {
      font-weight: bold;
    }
  }

  &__list-item-line {
    height: 3px;
    border-width: 0;
    width: 20%;
    min-width: 10px;
    background-color: var(--sf-grey-light);
    border-radius: var(--sf-border-radius);

    @media screen and (min-width: 768px) {
      width: 80%;
      min-width: 50px;
    }

    @media screen and (min-width: 920px) {
      width: 100%;
      min-width: 67px;
    }
  }
}

.steps-icon {
  circle {
    fill: var(--sf-grey-vlight);
  }
  path {
    fill: var(--sf-grey-mid);
  }
  &.steps-icon--selected {
    circle {
      fill: var(--sf-primary-yellow);
    }
    path {
      fill: var(--sf-white);
    }
  }
  &.completed {
    circle {
      fill: var(--sf-pale-green);
    }
    path {
      fill: none;
      stroke: var(--sf-white);
    }
  }
}
