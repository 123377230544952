.site-header {
  background: white;
  font-size: 10px;
  padding: 1.2em 0;
  @media (min-width: 600px) {
    font-size: 12px;
  }
  box-shadow: var(--sf-box-shadow-darker);
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo img {
    height: 4.5em;
    width: auto;
  }
  &__center {
    .container {
      justify-content: center;
    }
  }

  &__controls {
    display: flex;
    gap: 2.4em;
    button {
      font-size: 1.4em;
    }
  }
}
