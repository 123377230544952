.form__input {
  font-size: 16px;
  padding: 10.8px 18px;
  color: var(--sf-body-copy);
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--sf-grey-light);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 15px;

  &--postcode {
    margin-bottom: 0;
  }

  &--select-address {
    border: 1px solid var(--sf-grey-light);
    border-radius: 5px;
    background: url(https://shepherdsfriendly.blob.core.windows.net/image-media/icon-arrowDown.svg)
      95% center no-repeat;
  }

  &--dropdown {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("https://shepherdsfriendly.blob.core.windows.net/image-media/icon-arrowDown.svg")
      95% center no-repeat;
  }

  &--button {
    height: 43px;
  }
}

.address {
  &__search-button {
    display: block;
    align-self: end;
    flex-grow: unset;
    background-color: var(--sf-grey-vlight) !important;
    color: var(--sf-primary-blue) !important;
  }
}

label.sf-label {
  font-weight: bold;
  p {
    margin-bottom: 0;
    font-weight: normal;
  }
}
label[for="MemberGoldenCharter"] {
  font-weight: normal;
}

.NW20-radioBtn input[type="radio"] + label {
  color: #454e50;
  border: 1px solid var(--sf-grey-light);
  width: 98%;
  padding: 18px;
  background-color: transparent;
  border-radius: 3px;
  text-align: center !important;
  cursor: pointer;
  font-family: "Brandon Text", Arial;
  font-size: 16px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.multiselect-label {
  padding-left: 10px;
}

.form__container-application {
  padding-bottom: 100px;
  max-width: 547px;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.checkbox-container {
  padding: 10px 16px;
  margin-top: 4px;
  margin-bottom: 12px;
  display: inline-block;
  background: var(--sf-primary-orange-light);

  @media (max-width: 600px) {
    margin-right: 12px;
  }

  .dc-checkbox {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 1px solid #6e7576;
    border-radius: var(--sf-border-radius);
    background-color: var(--sf-white);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 12.47px;
      height: 9.2px;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6344 0.967454C13.0249 1.35798 13.0249 1.99114 12.6344 2.38167L5.43442 9.58167C5.0439 9.97219 4.41073 9.97219 4.02021 9.58167L0.747483 6.30894C0.356959 5.91842 0.356959 5.28525 0.747483 4.89473C1.13801 4.5042 1.77117 4.5042 2.1617 4.89473L4.72732 7.46035L11.2202 0.967454C11.6107 0.576929 12.2439 0.576929 12.6344 0.967454Z' fill='%2313243A'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      transform: scale(0);
      transform-origin: bottom left;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:checked {
      background-color: var(--sf-primary-yellow);
      border-color: var(--sf-primary-yellow);
      width: 24px;
      height: 24px;
    }
  }
}
