.sf-card {
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.05);
  border-radius: var(--sf-border-radius);
  padding: 3.2em;
  margin-bottom: 1.6em;
  position: relative;
  background: var(--sf-white);
  font-size: 10px;
  @media screen and (min-width:768px) {
    font-size: 16px;
  }
  @media screen and (min-width:1024px) {
    font-size: 20px;
  }
  &--claim {
    margin-bottom: 8em;
  }

  &__header {
    color: var(--sf-card-header);
    margin-bottom: 1em;
  }
  &__sub-header {
    color: var(--sf-card-sub-header);
    margin-bottom: 0.5em;
    font-weight: normal;
  }
  &__copy {
    color: var(--sf-body-copy);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--read {
    opacity: 0.5;
  }
}

