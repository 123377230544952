.claims {
  p {
    margin-bottom: 0;
  }
}
.dc-dashboard {
  margin-bottom: 140px;
}

.dc-dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;

  @media (max-width: 420px) {
    flex-direction: column;
  }

  h1 {
    color: var(--sf-primary-blue);
    margin: 40px 0;

    @media (max-width: 420px) {
      margin-bottom: 20px;
    }
  }

  button {
    flex-grow: 0;

    @media (max-width: 420px) {
      margin-bottom: 30px;
    }
  }
}

.dc-overview {
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  &__details {
    p {
      margin-bottom: 4px;
    }

    @media (max-width: 576px) {
      padding-bottom: 16px;
    }
  }

  &__controls {
    display: flex;
    min-width: 200px;
    justify-content: end;
    align-items: center;
    gap: 8px;

    @media (max-width: 576px) {
      justify-content: start;
    }

    button {
      flex-grow: 0;
    }
  }
}
