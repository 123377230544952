* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  background: #f5f6f6;
}

p {
  user-select: none;
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

a,
ul,
li {
  -webkit-tap-highlight-color: transparent;
}

.container {
  padding: 0 16px;

  @media (min-width: 1400px) {
    padding: 0 12px;
    margin: 0 300px;
  }
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // 1
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: transparent;
}

.NW20-tooltip-label-container p {
  margin-bottom: 0;
  font-weight: normal;
}

.btn-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  &.align-right {
    justify-content: end;
  }
}

.micro {
  padding-top: 12px;
  font-size: 12px;
  text-align: center;
}

.errorMessageContent {
  color: #fff;
  text-align: center;
  margin: 0px;
}

.errorMessage {
  display: inline-block;
  position: relative;
  padding: 6px 12px;
  border-radius: 1px;
  background: #ba2b2b;
  text-align: left;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: -0.2rem;
  width: auto;

  &::before {
    right: 85%;
    top: -11px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::before {
    border-color: rgba(255, 255, 255, 0);
    border-color: transparent transparent #ba2b2b transparent;
    border-width: 11px;
    margin-top: -10px;
  }
}

.deceased-plan-table {
  .form__input {
    margin-bottom: 0;
  }
}

.multiple {
  display: flex;
  gap: 20px;
  margin-top: 12px;
  &:last-child {
    margin-bottom: 15px;
  }
}

.text-info {
  margin: 0;
  padding: 0;
  color: var(--sf-box-shadow-darker);
}
